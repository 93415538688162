export const API_PREFIX = '/api/v1';
export const GET_INDUSTRY_SEGMENTS = API_PREFIX + '/BusinessEntity/IndustrySegments';
export const GET_PRIVACY_POLICY = API_PREFIX + '/PrivacyPolicy/';
export const GET_TERMS_OF_SERVICE = API_PREFIX + '/TermsOfService/';
export const GET_ITEMS_SIZES = API_PREFIX + '/Items/SizeTypes';
export const GET_STATES = API_PREFIX + '/Address/States';
export const API_AUTHENTICATION = API_PREFIX + '/Authentication';
export const API_REQUEST_VERIFICATION_EMAIL = API_PREFIX + '/ShipperProfile/ResendConfirmEmail';
export const API_USER_PROFILE = API_PREFIX + `/UserProfile/{0}`;
export const API_SHIPPER_PROFILE = API_PREFIX + `/ShipperProfile/{0}`;
export const API_SHIPPER_VALIDATE_PASSWORD = API_PREFIX + '/UserProfile/ValidateResetPassword';
export const API_SHIPPER_FORGOT_PASSWORD = API_PREFIX + '/ShipperProfile/ForgotPassword';
export const API_SHIPPER_RESET_PASSWORD = API_PREFIX + '/ShipperProfile/ResetPassword';
export const API_SHIPPER_CHANGE_PASSWORD = API_PREFIX + '/ShipperProfile/ChangePassword';
export const API_COMPANY_PROFILE = API_PREFIX + `/ShipperProfile/UpdateShipperProfileCompanyInformation`;

export const GET_POOLS = API_PREFIX + '/ShipperProfile/Pools';
export const SEARCH_DRIVERS = API_PREFIX + '/DriverProfile/Search';
export const API_GET_PARAM_RESET_PASSWORD_TOKEN = 'token';
export const GET_PAYMENT_METHODS_URL = API_PREFIX + '/Payment?excludeDraft=true';
export const API_TPRO3_AUTH = `${API_PREFIX}/AcctGateway/authorize`;
export const API_PAYMENT_ADD_CREDIT_CARD = `${API_PREFIX}/Payment/CreditCards`;

// admin
export const SEARCH_SHIPPERS = API_PREFIX + '/ShipperProfile/Search';
export const COMPLETE_STOP = API_PREFIX + '/Stop/{0}/Complete';
export const DISPATCH_ORDER = API_PREFIX + '/Order/Dispatch';
export const REOFFER_ORDER = API_PREFIX + '/Order/Reoffer';
export const RESCUE_ORDER = API_PREFIX + '/Order/Rescue';
export const UNDO_STOPCOMPLETE = API_PREFIX + '/Stop';
export const DRIVER_LOCATIONS = API_PREFIX + '/Driver/Locations';

// open Id constants;
export const API_OPEN_ID_GET_TOKEN = '/connect/token';
export const API_OPEN_ID_REVOCATION = '/connect/revocation';
export const API_REFRESH_TOKEN_GRANT_TYPE = 'refresh_token';
export const API_PASSWORD_GRANT_TYPE = 'password';
export const API_CLIENT_ID = 'webShipper';
export const API_SCOPE = 'api1 offline_access';

// Shipment Builder
export const API_ORDER = '/api/v1/Order';
export const API_ORDER_DELETE = API_PREFIX + '/Order/{0}';
export const API_ORDER_CONFIRM = `/api/v1/Order/{0}/confirm`;
export const API_CHECK_ADDRESS_MSA = `/api/v1/Order/CheckAddressIsInMSARange`;
export const API_ADDRESS = '/api/v1/Address/search';
export const API_ORDER_DISTANCE = '/api/v1/Order/Distance';
export const API_PRICE_OPTIMIZATION = '/api/v1/Order/{orderId}/OptimizedPrice';
export const API_EDIT_ORDER = API_PREFIX + '/order/{id}/edit';
export const API_SEARCH_AUTOCOMPLETE = '/api/v1/Address/searchAutoComplete';

// my orders
export const GET_INFRASTRUCTURE = API_PREFIX + '/infrastructure';
export const API_RATE_DRIVER = `${API_ORDER}/Rate/`;
export const RECURRING_ORDER = `${API_ORDER}/OrderRecurrence`;
export const GET_RECURRING_DETAIL = `${API_ORDER}/OrderRecurrence`;

// driver portal
export const GET_DRIVERS = API_PREFIX + '/driverPortal';
export const ACTIVATE_DEACT_DRIVER = API_PREFIX + '/driverPortal/Activate';
export const RESET_DRIVER_PASSWORD = API_PREFIX + '/driverPortal/resetPassword';
export const ADD_A_DRIVER = API_PREFIX + '/driverPortal/Register';
export const ADD_VEHICLE = API_PREFIX + '/driverPortal/AddVehicle';
export const UPDATE_VEHICLE = API_PREFIX + '/driverPortal/UpdateVehicle';
