import { MarkerUrls } from './../../core/constants/general.constants';
import { Marker } from '../../core/models/map.model';
import { TrackingInfoDto } from '../../core/models/dto';
import { map } from 'rxjs';
//import { OrderStatusId, StopStatusId, TrackingInfoDto } from '../../core/models/dto';
//import { StopType } from '../../shipment/models/order.model';

export class TrackingTransforms {
    public static transformTrackingInfoToMarkerArr(trackingInfo$) {
        return trackingInfo$.pipe(
            map((trackInfo: TrackingInfoDto) => {
                const stopInfo = trackInfo.stopInfo;
                const address = stopInfo.address;
                if (!stopInfo || !address) return [];

                const stopMarker: Marker = getStopMarker(stopInfo, address);

                //const expression = getExpression(trackInfo);
                // if (!expression || !trackInfo.driverArrivedAtFirstStop ) return [stopMarker];

                // show driver if they have a lat and long
                const driverMarker: Marker = getDriverMarker(trackInfo, stopInfo);
                if (driverMarker.latLng.lat && driverMarker.latLng.lng)
                    return [stopMarker, driverMarker];

                // catch. show stop marker only
                return [stopMarker];
            }));

        function getStopMarker(stopInfo, address): Marker {
            return {
                latLng: {
                    lat: parseFloat(address.latitude),
                    lng: parseFloat(address.longitude)
                },
                stopId: stopInfo.id,
                iconUrl: MarkerUrls[stopInfo.typeId]?.iconUrl,
                activeIconUrl: MarkerUrls[stopInfo.typeId]?.activeIconUrl,
                infoWindow: {
                    infoWindowHeader: stopInfo.nameOrDescription,
                    infoWindowBody: address.addressLine
                }
            };
        }

        // Driver Location (Real-time Driver Tracking)
        function getDriverMarker(trackInfo, stopInfo): Marker {
            return {
                latLng: {
                    lat: parseFloat(trackInfo.latitude),
                    lng: parseFloat(trackInfo.longitude)
                },
                stopId: stopInfo.id,
                iconUrl: 'assets/img/stops-list/marker_driver.png',
                activeIconUrl: 'assets/img/stops-list/marker_driver.png',
                infoWindow: {
                    infoWindowHeader: trackInfo.driverName,
                    infoWindowBody: formatDate(trackInfo.lastLocationUpdateTime)
                }
            };

            function formatDate(dateString) {
                const date = new Date(dateString);
                const options: Intl.DateTimeFormatOptions = {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true
                };
                return date.toLocaleString('en-US', options);
            }
        }

        /*function getExpression(trackInfo: TrackingInfoDto) {
            const handleStopExpNoDirect = trackInfo.stopInfo.typeId !== StopType.Pickup ?
                trackInfo.orderStatusId >= OrderStatusId.Assigned :
                (trackInfo.orderStatusId >= OrderStatusId.Assigned) && (trackInfo.stopInfo.statusId >= StopStatusId.DriverArrived);

            return (trackInfo.isDirectOrder && (trackInfo.orderStatusId >= OrderStatusId.Assigned)) ||
                (!trackInfo.isDirectOrder && handleStopExpNoDirect);
        }*/
    }
}