<div class="row expanded-row">
    <tuya-spinner *ngIf="isPoolsLoading$ | async" size="fullscreen"></tuya-spinner>

    <div class="col-md-6 order-info" *ngIf="!orderDetailsMode">
        <tuya-expanded-order-info [currentOrderInfo]="orderItem"></tuya-expanded-order-info>
    </div>
    <div class="driver-info" [ngClass]="{ 'col-md-6': !orderDetailsMode }">
        <div class="control-panel" [ngStyle]="{ 'background-color': driverInfoBgColor }"
            [ngClass]="{ loading: isLoading$ | async }">
            <div class="recurring" *ngIf="recurrenceOrderDetail">
                <div class="recurring__icon">
                    <div class="img"></div>
                </div>
                <div class="recurring__content">
                    <div class="title">This is a Recurring Order</div>
                    <div class="description">
                        {{ recurrenceOrderDetail.recurrenceDetail }}.
                    </div>
                </div>
                <div class="recurring__button">
                    <button mat-button class="accept" (click)="onRecurringOrderEdit()">
                        Edit
                    </button>
                </div>
            </div>

            <!-- Order Buttons -->
            <div *ngIf="!recurrenceOrderDetail">
                <button title="Duplicate Order" *ngIf="false" class="btn btn-secondary duplicate loader" [translate]="
                        !(isLoading$ | async)
                            ? 'tuya-my-orders.driver-info.duplicate'
                            : ''
                    "></button>

                <!-- Edit Order -->
                <button title="Edit Order" class="btn btn-secondary loader"
                    *ngIf="!isShipperSuspended && orderDetailsMode" [title]="
                        isEditOrderDisabled()
                            ? ('tuya-my-orders.order-info.can-not-edit'
                              | translate)
                            : ''
                    " [disabled]="
                        !(isLoading$ | async) ? isEditOrderDisabled() : false
                    " [translate]="
                        !(isLoading$ | async)
                            ? 'tuya-my-orders.driver-info.edit-order'
                            : ''
                    " (click)="editOrder()"></button>

                <!-- Order Details -->
                <button title="Order Details" class="btn btn-secondary loader"
                    *ngIf="!isShipperSuspended && !orderDetailsMode" [title]="
                        'tuya-my-orders.order-info.order-details' | translate
                    " [translate]="
                        !(isLoading$ | async)
                            ? 'tuya-my-orders.driver-info.order-details'
                            : ''
                    " (click)="editOrder()"></button>

                <!-- Cancel Order -->
                <button title="Cancel Order" class="btn btn-secondary loader"
                    (click)="openModal(isAdmin ? cancelModal : template)" [disabled]="
                        !(isLoading$ | async) ? isCancelDisabled() : false
                    " [title]="
                        isCancelDisabled()
                            ? ('tuya-my-orders.order-info.can-not-cancel'
                              | translate)
                            : ''
                    " [translate]="
                        !(isLoading$ | async)
                            ? 'tuya-my-orders.driver-info.cancel'
                            : ''
                    "></button>

                <!-- ADMIN: Dispatch, Re-Offer, and Rescue for Offers/Orders -->
                <button class="btn btn-secondary loader" *ngIf="isAdmin && !getAdminButtonState()?.disabled"
                    [title]="getAdminButtonState()?.label | translate" [disabled]="
                        !(isLoading$ | async)
                            ? getAdminButtonState().disabled
                            : false
                    " (click)="
                        openOfferModal(
                            offerModal,
                            getAdminButtonState()?.action
                        )
                    ">
                    {{ getAdminButtonState()?.label | translate }}
                </button>
            </div>
        </div>

        <tuya-expanded-driver-info [order]="orderItem" [combinePools]="combinePools" [pools]="combinedPools$ | async"
            [isShipperSuspended]="isShipperSuspended" [isLoading]="isLoading$ | async"
            (createPool)="onCreatePool($event)" (sendPools)="onSendPools($event)" (removePools)="onRemovePools($event)"
            (updateRate)="onUpdateRate($event)" (editOrder)="editOrder()"></tuya-expanded-driver-info>
    </div>
</div>

<ng-template #template>
    <div class="modal-body text-center">
        <h3>{{ "tuya-my-orders.modals.wantCancelOrder" | translate }}</h3>
        <button type="button" class="btn btn-default btn-lg yes-button" (click)="confirm()">
            {{ "tuya-my-orders.modals.yes" | translate }}
        </button>
        <button type="button" class="btn btn-primary btn-lg no-button" (click)="decline()">
            {{ "tuya-my-orders.modals.no" | translate }}
        </button>
    </div>
    <tuya-spinner *ngIf="isLoading$ | async" size="fullcontained"></tuya-spinner>
</ng-template>

<ng-template #cancelModal>
    <div class="modal-content">
        <div class="modal-title">
            {{
            "tuya-my-orders.modals.adminWantToCancel"
            | translate : { code: _orderItem.code }
            }}
        </div>
        <div class="comment-container">
            <div class="comments-heading">
                {{ "tuya-my-orders.modals.comments" | translate }}
            </div>
            <textarea class="comment-field" maxlength="280" name="comments" placeholder="{{
                    'tuya-my-orders.modals.mandatory' | translate
                }}" [(ngModel)]="cancelComment">
            </textarea>
        </div>
        <div class="button-container">
            <button type="button" class="btn" (click)="confirm()" [disabled]="isConfirmModalDisabled()">
                Confirm Cancellation
                <tuya-spinner *ngIf="isLoading$ | async" size="fullcontained"></tuya-spinner>
            </button>
            <button type="button" class="btn alt" (click)="decline()">
                Go Back
            </button>
        </div>
    </div>
</ng-template>

<ng-template #offerModal>
    <div class="modal-content offer">
        <div class="offer-header-container">
            <!-- Offer Header Text -->
            <div class="offer-header upper-left">
                {{ "tuya-my-orders.modals." + currentActionText.toLowerCase() | translate }}
            </div>
            <!-- Instruction Text -->
            <div class="offer-header-container">
                <div class="offer-header-text below-header">
                    {{ "tuya-my-orders.modals." + currentActionText.toLowerCase() + "-order" | translate }}
                </div>
                <div class="offer-instruction">
                    {{ "tuya-my-orders.modals." + currentActionText.toLowerCase() + "-please-select" | translate }}
                </div>
            </div>
        </div>
        <!-- Assign Driver Component -->
        <tuya-assign-driver [pools]="pools$ | async" [drivers]="drivers$ | async" [assignments]="assignments"
            [isExtendable]="isExtendable$ | async" [isDispatchOrRescue]="isDispatchOrRescue"
            [vehicleType]="vehicleType$ | async" [offerType]="offerType$ | async"
            [isDriversLoading]="isDriversLoading$ | async" (driverSearch)="onDriverSearch($event)"
            (driverTypeOffer)="onDriverOffer($event)" (setExtendable)="onSetExtendable($event)">
        </tuya-assign-driver>
        <div class="button-container reoffer">
            <button type="button" class="btn chng reoffer" (click)="submitAction()"
                [disabled]="isReofferModalDisabled()">
                {{
                "tuya-my-orders.modals." +
                currentActionText.toLowerCase() +
                "-submit" | translate
                }}
            </button>
            <button type="button" class="btn alt reoffer" (click)="cancelReoffer()">
                {{ "tuya-my-orders.modals.cancel" | translate }}
            </button>
        </div>
    </div>
</ng-template>