<div class="search-input-container">
    <div class="search-label">
        {{ "tuya-admin.search-page.search-for" | translate }}:
    </div>
    <form (ngSubmit)="onSubmit()" #f="ngForm">
        <input
            #searchBox
            type="text"
            placeholder="{{
                'tuya-admin.search-page.search-placeholder' | translate
            }}"
            [(ngModel)]="searchTerm"
            #search="ngModel"
            name="search"
        />
        <button title="Look Up Shipper" class="search-button" type="submit">
            <div class="magnifying-glass"></div>
        </button>
    </form>
</div>

<div class="search-results-container">
    <ng-container
        *ngIf="isLoading$ | async; then loading; else notLoading"
    ></ng-container>
</div>

<!-- loading state -->
<ng-template #loading>
    <div class="search-message">
        <div class="icon relative">
            <tuya-spinner size="fullwindow" clear="true"></tuya-spinner>
        </div>
    </div>
</ng-template>

<ng-template #notLoading>
    <ng-container
        *ngIf="
            (shipperResults$ | async) && (shipperResults$ | async).length;
            then results;
            else noResultsFork
        "
    ></ng-container>
</ng-template>

<!-- result/empty/start content -->
<ng-template #results>
    <ng-container *ngIf="shippersHaveValidData(); else noResults">
        <div
            class="search-results-title"
            [innerHtml]="
                'tuya-admin.search-page.search-results-title'
                    | translate : { term: (shipperSearchTerm$ | async) }
            "
        ></div>
        <table class="results-table">
            <thead>
                <tr>
                    <th class="col-name">
                        {{ "tuya-admin.search-page.headers.name" | translate }}
                    </th>
                    <th class="col-contact">
                        {{
                            "tuya-admin.search-page.headers.contact" | translate
                        }}
                    </th>
                    <th class="col-email">
                        {{ "tuya-admin.search-page.headers.email" | translate }}
                    </th>
                    <th class="col-phone">
                        {{ "tuya-admin.search-page.headers.phone" | translate }}
                    </th>
                    <th class="col-account">
                        {{
                            "tuya-admin.search-page.headers.account" | translate
                        }}
                    </th>
                    <th class="col-arrow"></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let shipper of shipperResults$ | async">
                    <tr
                        *ngIf="
                            shipper.fullName !== null &&
                            shipper.email !== null &&
                            shipper.companyName !== null
                        "
                        (click)="selectShipper(shipper.userAccountId)"
                    >
                        <td
                            class="col-name"
                            [innerHtml]="boldSearchTerm(shipper.companyName)"
                        ></td>
                        <td
                            class="col-contact"
                            [innerHtml]="boldSearchTerm(shipper.fullName)"
                        ></td>
                        <td
                            class="col-email"
                            [innerHtml]="boldSearchTerm(shipper.email)"
                        ></td>
                        <td
                            class="col-phone"
                            [innerHtml]="
                                boldSearchTerm(shipper.phone | phoneNumber)
                            "
                        ></td>
                        <td
                            class="col-account"
                            [innerHtml]="boldSearchTerm(shipper.accountNumber)"
                        ></td>
                        <td class="col-arrow">
                            <div class="arrow"></div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>

        <div class="pagination flex-container">
            <div
                class="edge-flex"
                *ngIf="(shipperSearchCurrentPage$ | async) > 1"
                (click)="previousPage()"
            >
                Previous
            </div>
            <div class="center-flex">
                Page {{ shipperSearchCurrentPage$ | async }} of
                {{
                    templateMath.ceil(
                        (shipperResultsCount$ | async) /
                            (shipperSearchResultsPerPage | async)
                    )
                }}
            </div>
            <div
                class="edge-flex"
                *ngIf="
                    (shipperSearchCurrentPage$ | async) *
                        (shipperSearchResultsPerPage | async) <
                    (shipperResultsCount$ | async)
                "
                (click)="nextPage()"
            >
                Next
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #noResultsFork>
    <ng-container
        *ngIf="shipperSearchTerm$ | async; then noResults; else startResults"
    ></ng-container>
</ng-template>

<ng-template #noResults>
    <div class="search-message">
        <div class="icon no-results-icon"></div>
        <p class="subtext">
            {{
                "tuya-admin.search-page.no-results"
                    | translate : { term: (shipperSearchTerm$ | async) }
            }}
        </p>
    </div>
</ng-template>

<ng-template #startResults>
    <div class="map-container">
        <tuya-map
            mode="container-size"
            [markers]="createMarkers(drivers$ | async)"
            [autoBoundary]="markers.length > 2"
            [screenChangeMode]="true"
            [mapCenter]="mapCenter$ | async"
        >
        </tuya-map>
    </div>
</ng-template>
