import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { SEARCH_SHIPPERS, COMPLETE_STOP, DISPATCH_ORDER, REOFFER_ORDER, RESCUE_ORDER, UNDO_STOPCOMPLETE, DRIVER_LOCATIONS } from '../../core/constants/api.constants';
import { BaseService } from '../../core/services/base.service';
import { ConfigService } from '../../app.config.service';
import { map, Observable } from 'rxjs';
import { ShipperResult, CompleteStopDto } from '../models/admin.model';
import { DriversLocationsDto } from 'app/core/models/dto';

@Injectable()
export class AdminService extends BaseService {
  constructor(http: HttpClient, configService: ConfigService) {
    super(http, configService);
  }

  searchForShipper(searchParams: any): Observable<ShipperResult[]> {
    // TODO: define search params model
    return this.api().GET(SEARCH_SHIPPERS, searchParams);
  }

  completeStop(params) {
    const url = COMPLETE_STOP.replace(params.stopId ? '{0}' : '/{0}', params.stopId || '');
    const newParams: CompleteStopDto = {
      receiverName: params.receiverName,
      stopId: params.stopId,
      stopNotes: params.stopNotes,
      timestamp: params.timestamp
    };
    return this.api().PUT(url, newParams);
  }

  dispatchOrder(params): Observable<any> {
    return this.api().PUT(DISPATCH_ORDER, params);
  }

  reofferOrder(params): Observable<any> {
    return this.api().PUT(REOFFER_ORDER, params);
  }

  rescueOrder(params): Observable<any> {
    return this.api().PUT(RESCUE_ORDER, params);
  }

  undoStopComplete(stopId: number): Observable<any> {
    return this.api().PUT(`${UNDO_STOPCOMPLETE}/${stopId}/UndoComplete`);
  }

  getDriversLocations(limit: number): Observable<DriversLocationsDto[]> {    
    return this.api().GET(`${DRIVER_LOCATIONS}/${limit}`).pipe(map((data: DriversLocationsDto[]) => data));;
  }
}
