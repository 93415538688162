import { Injectable } from '@angular/core';
import { combineEpics, ofType } from 'redux-observable';
import { concat, of } from 'rxjs';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import {
  ShipperAdminActions,
  SHIPPER_SEARCH,
  SELECT_SHIPPER,
  COMPLETE_STOP,
  ADMIN_DISPATCH_EDIT_ACTION,
  ADMIN_DISPATCH_EDIT_ACTION_SUCCEEDED,
  ADMIN_REOFFER_EDIT_ACTION,
  ADMIN_REOFFER_EDIT_ACTION_SUCCEEDED,
  ADMIN_RESCUE_EDIT_ACTION,
  ADMIN_RESCUE_EDIT_ACTION_SUCCEEDED,  
  ADMIN_DISPATCH_ORDER,
  ADMIN_REOFFER_ORDER,
  ADMIN_RESCUE_ORDER,
  LOAD_SHIPPER_AND_COMPANY_PROFILES_AS_ADMIN,
  UNDO_STOP_COMPLETE,
  SHIPPER_TABLE_PAGINATE,
  LOAD_DRIVER_LOCATIONS
} from '../actions/shipper.admin.actions';
import { UPDATE_LOCATION } from '@angular-redux-ivy/router';

import { AdminService } from '../services/admin.service';
import { ShipperService } from '../../core/services/shipper.service';
import { IAppState } from '../../store/model';
import { AlertActions } from '../../core/actions/alert.actions';
import { AlertType } from '../../core/models/alert.model';
import { BuilderService } from '../../builder/services/builder.service';
import { MyOrdersActions } from '../../home/actions/my-orders.actions';
import { DriversLocationsDto, OrderStatusId, ShipperProfileResultDto } from '../../core/models/dto';
import { ShipmentActions } from '../../shipment/actions/shipment.actions';
import { UIActions } from '../../shipment/actions/ui.actions';
import { transformShipperProfile } from '../../shipper/utils/shipper-profile.utils';
import { TranslateService } from '@ngx-translate/core';
import { ShipperActions } from 'app/shipper/actions/shipper.status.actions';
import { AuthActions } from 'app/auth/actions/auth.actions';
import { exhaustMap, map, switchMap, mergeMap, catchError } from 'rxjs/operators';
import { MapActions } from 'app/core/actions/map.actions';

@Injectable()
export class AdminEpics {
  constructor(
    private shipperAdminActions: ShipperAdminActions,
    private adminService: AdminService,
    private shipperService: ShipperService,
    private router: Router,
    private alertActions: AlertActions,
    private myOrdersActions: MyOrdersActions,
    private shipmentActions: ShipmentActions,
    private uiActions: UIActions,
    private builderService: BuilderService,
    private translationService: TranslateService,
    private shipperActions: ShipperActions,
    private authActions: AuthActions,
    private mapActions: MapActions
  ) { }

  public createEpic() {
    return combineEpics(
      createShipperSearchEpic(this.shipperAdminActions, this.adminService),
      createShipperSearchPrevNextPageEpic(this.shipperAdminActions),
      createSelectShipperEpic(this.router),
      createLoadShipperAndCompanyProfilesAsAdminEpic(this.shipperAdminActions, this.shipperService, this.shipperActions, this.authActions, this.mapActions),
      createShipmentOrderEditEpic(this.shipperAdminActions, this.builderService, this.alertActions, this.myOrdersActions, this.translationService),
      createShipmentDispatchOrderEditSucceedEpic(this.shipmentActions, this.uiActions),
      createShipmentOrderEditSucceedEpic(this.shipmentActions, this.uiActions),
      createShipmentRescueOrderEditSucceedEpic(this.shipmentActions, this.uiActions),
      createAdminDispatchEpic(this.shipperAdminActions, this.adminService, this.alertActions, this.myOrdersActions),
      createAdminReofferEpic(this.shipperAdminActions, this.adminService, this.alertActions, this.myOrdersActions),
      createAdminRescueEpic(this.shipperAdminActions, this.adminService, this.alertActions, this.myOrdersActions),
      createCompleteStopEpic(this.shipperAdminActions, this.adminService, this.alertActions),
      createDriversLocationsEpic(this.shipperAdminActions, this.adminService, this.alertActions),
      createUndoStopCompleteEpic(this.shipperAdminActions, this.adminService, this.alertActions, this.myOrdersActions)
    );
  }
}

export function createShipperSearchEpic(shipperAdminActions: ShipperAdminActions, adminService: AdminService) {
  return (action$, store) => action$.pipe(
    ofType(SHIPPER_SEARCH),
    exhaustMap((action: any) => {
      return adminService.searchForShipper({
        value: action.payload.value,
        pageSize: action.payload.pageSize,
        pageNumber: action.payload.currentPageNumber
      }).pipe(
        map((data: any) => {
          return shipperAdminActions.shipperSearchSuccess(data);
        })
        , catchError(error => of(shipperAdminActions.shipperSearchFail({ error })))); // have not tested this yet
    })
  );
}

export function createShipperSearchPrevNextPageEpic(shipperAdminActions: ShipperAdminActions) {
  return (action$, store) => action$.pipe(
    ofType(SHIPPER_TABLE_PAGINATE), // handles both prev and next, because epic runs after reducer
    map(() => {
      const shipperAdmin = store.value.admin.shipperAdmin;
      const term = shipperAdmin.shipperSearchTerm;
      const currentPageNumber = shipperAdmin.shipperSearchCurrentPage;
      const pageSize = shipperAdmin.shipperSearchResultsPerPage;
      return shipperAdminActions.shipperSearch({
        value: term,
        pageSize,
        currentPageNumber
      });
    })
  );
}

export function createSelectShipperEpic(router: Router) {
  return (action$) => action$.pipe(
    ofType(SELECT_SHIPPER),
    map(() => {
      router.navigate(['/orders']);
      return {
        type: UPDATE_LOCATION,
        payload: '/orders',
      };
    })
  );
}

export function createLoadShipperAndCompanyProfilesAsAdminEpic(shipperAdminActions: ShipperAdminActions,
  shipperService: ShipperService,
  shipperActions: ShipperActions,
  authActions: AuthActions,
  mapActions: MapActions) {
  return (action$) => action$.pipe(
    ofType(LOAD_SHIPPER_AND_COMPANY_PROFILES_AS_ADMIN),
    exhaustMap((action: any) => {
      return shipperService.get(action.payload).pipe(
        mergeMap((data: ShipperProfileResultDto) => {
          const shipperProfile = transformShipperProfile(data);

          console.debug('ShipperProfileResultDto', shipperProfile);

          const st: IAppState = JSON.parse(sessionStorage.getItem('state'));
          if (st.router === '/admin-search' || st.router === '/driver-portal') {
            return [authActions.updateShipperProfileToState(shipperProfile),  // auth->currentUser->shipperProfile
            shipperActions.loadProfileSucceeded(shipperProfile), // shipper->shipperStatus
            shipperAdminActions.selectShipper({ shipper: shipperProfile }), // set admin->selectedShipper
            shipperAdminActions.loadShipperAndCompanyProfilesAsAdminSuccess(shipperProfile),
            shipperAdminActions.setIsLoadingFalse(),
            mapActions.setCenter({
              lat: shipperProfile.businessEntity.physicalAddress.latitude,
              lng: shipperProfile.businessEntity.physicalAddress.longitude
            })
            ];
          } else {
            return [shipperActions.loadProfileSucceeded(shipperProfile), // shipper->shipperStatus
            authActions.updateShipperProfileToState(shipperProfile),  // auth->currentUser->shipperProfile
            shipperAdminActions.loadShipperAndCompanyProfilesAsAdminSuccess(shipperProfile),
            shipperAdminActions.setIsLoadingFalse(),
            mapActions.setCenter({
              lat: shipperProfile.businessEntity.physicalAddress.latitude,
              lng: shipperProfile.businessEntity.physicalAddress.longitude
            })
            ];
          }
        })
        , catchError((err) => {
          console.error(err);
          return of(shipperAdminActions.loadShipperAndCompanyProfilesAsAdminFail(err), shipperAdminActions.setIsLoadingFalse());
        }));
    })
  );
}

export function createCompleteStopEpic(shipperAdminActions: ShipperAdminActions, adminService: AdminService, alertActions: AlertActions) {
  return (action$) => action$.pipe(
    ofType(COMPLETE_STOP),
    exhaustMap((action: any) => {
      return adminService.completeStop({ ...action.payload }).pipe(
        map(() => {
          action.payload.success();  // run success callback
          return shipperAdminActions.completeStopSuccess();
        })
        , catchError((err) => {
          console.error(err);
          action.payload.failure();  // run failure callback
          return of(shipperAdminActions.completeStopFailure(), alertActions.show({ error: err, type: AlertType.BadRequest }));
        }));
    })
  );
}

export function createShipmentDispatchOrderEditEpic(shipperAdminActions: ShipperAdminActions, ordersService: BuilderService,
  alert: AlertActions, myOrdersActions: MyOrdersActions,
  translationService: TranslateService) {
  return (action$) => action$.pipe(
    ofType(ADMIN_DISPATCH_EDIT_ACTION),
    switchMap((action: any) => {
      return ordersService.getEditOrder(action.payload.orderId, action.payload.isDuplicateMode).pipe(
        map((data: any) => { // Add type annotation for 'data'
          if (data.statusId !== OrderStatusId.Completed && data.statusId !== OrderStatusId.CompletedWithExceptions) {
            return shipperAdminActions.dispatchEditOrderSucceeded(data);
          } else {
            alert.show({
              message: translationService.instant('tuya-alert.edit-disabled'),
              type: AlertType.Error
            });
            return myOrdersActions.updateOrderStatus({ ...data, orderId: action.payload, statusId: data.statusId });
          }
        })
        , catchError(error => {
          console.warn('DISPATCH_EDIT_ORDER_ACTION Failed', error);
          return of(shipperAdminActions.dispatchEditOrderFailed({
            error,
          }));
        }));
    })
  );
}

export function createShipmentDispatchOrderEditSucceedEpic(shipmentActions: ShipmentActions, uiActions: UIActions) {
  return (action$) => action$.pipe(
    ofType(ADMIN_DISPATCH_EDIT_ACTION_SUCCEEDED),
    mergeMap((action: any) => {
      const orderVo = action.payload;
      return concat(
        of(uiActions.setDisableStateForRefreshOrder(true)),
        of(shipmentActions.shipmentOrderSaveToState(orderVo)));
    })
  );
}

export function createShipmentOrderEditEpic(shipperAdminActions: ShipperAdminActions, ordersService: BuilderService,
  alert: AlertActions, myOrdersActions: MyOrdersActions,
  translationService: TranslateService) {
  return (action$) => action$.pipe(
    ofType(ADMIN_REOFFER_EDIT_ACTION),
    switchMap((action: any) => {
      return ordersService.getEditOrder(action.payload.orderId, action.payload.isDuplicateMode).pipe(
        map((data: any) => { // Add type annotation for 'data'
          if (data.statusId !== OrderStatusId.Completed && data.statusId !== OrderStatusId.CompletedWithExceptions) {
            return shipperAdminActions.reofferEditOrderSucceeded(data);
          } else {
            alert.show({
              message: translationService.instant('tuya-alert.edit-disabled'),
              type: AlertType.Error
            });
            return myOrdersActions.updateOrderStatus({ ...data, orderId: action.payload, statusId: data.statusId });
          }
        })
        , catchError(error => {
          console.warn('REOFFER_EDIT_ORDER_ACTION Failed', error);
          return of(shipperAdminActions.reofferEditOrderFailed({
            error,
          }));
        }));
    })
  );
}

export function createShipmentOrderEditSucceedEpic(shipmentActions: ShipmentActions, uiActions: UIActions) {
  return (action$) => action$.pipe(
    ofType(ADMIN_REOFFER_EDIT_ACTION_SUCCEEDED),
    mergeMap((action: any) => {
      const orderVo = action.payload;
      return concat(
        of(uiActions.setDisableStateForRefreshOrder(true)),
        of(shipmentActions.shipmentOrderSaveToState(orderVo)));
    })
  );
}

export function createShipmentRescueOrderEditEpic(shipperAdminActions: ShipperAdminActions, ordersService: BuilderService,
  alert: AlertActions, myOrdersActions: MyOrdersActions,
  translationService: TranslateService) {
  return (action$) => action$.pipe(
    ofType(ADMIN_RESCUE_EDIT_ACTION),
    switchMap((action: any) => {
      return ordersService.getEditOrder(action.payload.orderId, action.payload.isDuplicateMode).pipe(
        map((data: any) => { // Add type annotation for 'data'
          if (data.statusId !== OrderStatusId.Completed && data.statusId !== OrderStatusId.CompletedWithExceptions) {
            return shipperAdminActions.rescueEditOrderSucceeded(data);
          } else {
            alert.show({
              message: translationService.instant('tuya-alert.edit-disabled'),
              type: AlertType.Error
            });
            return myOrdersActions.updateOrderStatus({ ...data, orderId: action.payload, statusId: data.statusId });
          }
        })
        , catchError(error => {
          console.warn('RESCUE_EDIT_ORDER_ACTION Failed', error);
          return of(shipperAdminActions.dispatchEditOrderFailed({
            error,
          }));
        }));
    })
  );
}

export function createShipmentRescueOrderEditSucceedEpic(shipmentActions: ShipmentActions, uiActions: UIActions) {
  return (action$) => action$.pipe(
    ofType(ADMIN_RESCUE_EDIT_ACTION_SUCCEEDED),
    mergeMap((action: any) => {
      const orderVo = action.payload;
      return concat(
        of(uiActions.setDisableStateForRefreshOrder(true)),
        of(shipmentActions.shipmentOrderSaveToState(orderVo)));
    })
  );
}

export function createAdminDispatchEpic(shipperAdminActions: ShipperAdminActions, adminService: AdminService,
  alertActions: AlertActions, myOrderActions: MyOrdersActions) {
  return (action$) => action$.pipe(
    ofType(ADMIN_DISPATCH_ORDER),
    mergeMap((action: any) => {
      const order = action.payload;
      return adminService.dispatchOrder(order).pipe(
        map(data => {
          action.payload.success();
          myOrderActions.updateOrderStatus({ statusId: OrderStatusId.Offered, orderId: order.orderVersionId });
          return shipperAdminActions.dispatchOrderSuccess(data);
        })
        , catchError(error => {
          action.payload.fail();
          return of(
            shipperAdminActions.dispatchOrderFailure({ error }),
            alertActions.show({ error: error, type: AlertType.BadRequest })
          );
        }));
    })
  );
}

export function createAdminReofferEpic(shipperAdminActions: ShipperAdminActions, adminService: AdminService,
  alertActions: AlertActions, myOrderActions: MyOrdersActions) {
  return (action$) => action$.pipe(
    ofType(ADMIN_REOFFER_ORDER),
    mergeMap((action: any) => {
      const order = action.payload;
      return adminService.reofferOrder(order).pipe(
        map(data => {
          action.payload.success();
          myOrderActions.updateOrderStatus({ statusId: OrderStatusId.Offered, orderId: order.orderVersionId });
          return shipperAdminActions.reofferOrderSuccess(data);
        })
        , catchError(error => {
          action.payload.fail();
          return of(
            shipperAdminActions.reofferOrderFailure({ error }),
            alertActions.show({ error: error, type: AlertType.BadRequest })
          );
        }));
    })
  );
}

export function createAdminRescueEpic(shipperAdminActions: ShipperAdminActions, adminService: AdminService,
  alertActions: AlertActions, myOrderActions: MyOrdersActions) {
  return (action$) => action$.pipe(
    ofType(ADMIN_RESCUE_ORDER),
    mergeMap((action: any) => {
      const order = action.payload;
      return adminService.dispatchOrder(order).pipe(
        map(data => {
          action.payload.success();
          myOrderActions.updateOrderStatus({ statusId: OrderStatusId.Offered, orderId: order.orderVersionId });
          return shipperAdminActions.rescueOrderSuccess(data);
        })
        , catchError(error => {
          action.payload.fail();
          return of(
            shipperAdminActions.rescueOrderFailure({ error }),
            alertActions.show({ error: error, type: AlertType.BadRequest })
          );
        }));
    })
  );
}

export function createUndoStopCompleteEpic(shipperAdminActions: ShipperAdminActions, adminService: AdminService,
  alertActions: AlertActions, myOrderActions: MyOrdersActions) {
  return (action$) => action$.pipe(
    ofType(UNDO_STOP_COMPLETE),
    mergeMap((action: any) => {
      const stopId = action.payload.stopId;
      return adminService.undoStopComplete(stopId).pipe(
        map(data => {
          action.payload.success();
          return shipperAdminActions.undoStopComleteSuccess();
        })
        , catchError(error => {
          return of(shipperAdminActions.undoStopComleteFailure());
        }));
    })
  );
}

export function createDriversLocationsEpic(shipperAdminActions: ShipperAdminActions, adminService: AdminService, alertActions: AlertActions) {
  return (action$) => action$.pipe(
    ofType(LOAD_DRIVER_LOCATIONS),
    exhaustMap((action: any) => {
      return adminService.getDriversLocations(20).pipe(
        map((data: DriversLocationsDto[]) => {
          return shipperAdminActions.loadDriverLocationsSucceeded(data);
        }),
        catchError((err) => {
          console.error(err);
          return of(shipperAdminActions.loadDriverLocationsFailed, alertActions.show({ error: err, type: AlertType.BadRequest }));
        }));
    })
  );
}