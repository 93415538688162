import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { NotificationActions } from '../actions/notification.actions';
import { MyOrdersActions } from '../../home/actions/my-orders.actions';
import { LoadNotificationsParamsDto } from '../models/dto';
import { PAGE_SIZE } from '../containers/notification-panel-container/notification-panel-container.component';

//import * as firebase from 'firebase/app';
import { environment } from 'environments/environment.prod';
import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, isSupported, Messaging } from 'firebase/messaging';

@Injectable({
    providedIn: 'root',
  })
  export class PushNotificationService {
    app = initializeApp(environment.firebase);
    messaging: Messaging | null = null; // Initialize only if supported
    currentMessage = new BehaviorSubject(null);
  
    constructor(
      private notificationActions: NotificationActions,
      private myOrdersActions: MyOrdersActions
    ) {
      this.initializeMessaging();
    }
  
    private async initializeMessaging(): Promise<void> {
      try {
        const messagingSupported = await isSupported();
        if (messagingSupported) {
          this.messaging = getMessaging(this.app);
          console.debug('Firebase Messaging initialized');
          this.receiveMessage(); // Set up message handling after initialization
        } else {
          console.warn('Firebase Messaging is not supported in this browser.');
        }
      } catch (error) {
        console.error('Error initializing Firebase Messaging:', error);
      }
    }
  
    receiveMessage(): void {
      if (!this.messaging) {
        console.warn('Cannot receive messages: Firebase Messaging is not initialized or supported.');
        return;
      }
  
      onMessage(this.messaging, (payload: any) => {
        try {
          payload.data.payload = JSON.parse(payload.data.payload);
          payload.data.bucketItem = JSON.parse(payload.data.bucketItem);
          this.myOrdersActions.updateOrders();
  
          const mess = {
            id: payload.data.bucketItem.id,
            type: payload.data.bucketItem.highlightingTypeId,
            messageBody: payload.notification.body,
            title: payload.notification.title,
            orderId: payload.data.payload.orderId,
          };
  
          this.notificationActions.add(mess);
          this.notificationActions.clearNotificationBucket();
  
          const bucket = {
            pageSize: PAGE_SIZE,
            currentPageNumber: 1,
            totalItemCountRequired: true,
          };
  
          setTimeout(() => {
            this.notificationActions.remove(mess);
          }, 5000);
  
          this.currentMessage.next(payload);
        } catch (error) {
          console.error('Error processing received message:', error);
        }
      });
    }
  }

/*@Injectable()
export class PushNotificationService {
    //messaging = firebase.messaging();
    app = initializeApp(environment.firebase);
    
    messaging = getMessaging(this.app);

    currentMessage = new BehaviorSubject(null);

    constructor(private notificationActions: NotificationActions, private myOrdersActions: MyOrdersActions) { }

    receiveMessage() {
        onMessage(this.messaging, (payload: any) => {
            payload.data.payload = JSON.parse(payload.data.payload);
            payload.data.bucketItem = JSON.parse(payload.data.bucketItem);
            this.myOrdersActions.updateOrders();
            const mess = {
                id: payload.data.bucketItem.id,
                type: payload.data.bucketItem.highlightingTypeId,
                messageBody: payload.notification.body,
                title: payload.notification.title,
                orderId: payload.data.payload.orderId,
            };
            this.notificationActions.add(mess);
            this.notificationActions.clearNotificationBucket();
            const bucket = {
                pageSize: PAGE_SIZE,
                currentPageNumber: 1,
                totalItemCountRequired: true,
            };
            setTimeout(() => {
                this.notificationActions.remove(mess);
            }, 5000);
            this.currentMessage.next(payload);
        });
    }
}
*/

/*this.messaging.onMessage((payload: any) => {
    payload.data.payload = JSON.parse(payload.data.payload);
    payload.data.bucketItem = JSON.parse(payload.data.bucketItem);
    this.myOrdersActions.updateOrders();
    const mess = {
        id: payload.data.bucketItem.id,
        type: payload.data.bucketItem.highlightingTypeId,
        messageBody: payload.notification.body,
        title: payload.notification.title,
        orderId: payload.data.payload.orderId
    };
    this.notificationActions.add(mess);
    this.notificationActions.clearNotificationBucket();
    const bucket = <LoadNotificationsParamsDto>{
        pageSize: PAGE_SIZE,
        currentPageNumber: 1,
        totalItemCountRequired: true
    };
    setTimeout(() => {
        this.notificationActions.remove(mess);
    }, 5000);
    this.currentMessage.next(payload);
});*/