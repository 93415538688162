export const locale = {
    'lang': 'en',
    'data': {
        'tuya-my-orders': {
            'table-headers': {
                'date': 'Date Submitted',
                'dateCompleted': 'Date Completed',
                'recurring': 'Recuring',
                'code': 'Order #',
                'stopFrom': 'From Stop',
                'stopTo': 'To Stop',
                'nStops': '#STOPS',
                'driverPro': 'Driver',
                'total': 'Amount',
                'statusId': 'Status',
                'details': '',
                'details-link': 'Details',
                'noDriver': 'No driver assigned',
                'myOrders': 'My Orders',
                'draft': ' draft',
                'declined': ' declined',
                'released': ' released',
                'searcHHeader': 'Search Results',
                'searchSubHeaderOver': ' of over',
                'searchSubHeaderForQuery': ' results for'
            },
            'table-filters': {
                'draft-tooltip': 'Draft',
                'draft': 'DRAFT',
                'open-tooltip': 'Offered, Assigned, In Progress',
                'open': 'OPEN',
                'closed-tooltip': 'Completed, Completed with Exceptions, Cancelled',
                'closed': 'CLOSED',
                'blocked-tooltip': 'Released, Declined, Expired',
                'blocked': 'BLOCKED',
                'nearing-deadline': 'NEARING DEADLINE',
                'has-exception': 'HAS EXCEPTION',
                'noOrders': 'No Orders',
                'noOrdersDescrpt': 'It looks like we don\'t have any orders to show you under the currently selected filters.',
                'searchFilter': 'Filter'
            },
            'order-info': {
                'is-simpified': 'TUYA NOW MOBILE',
                'not-simplified': 'TUYA NOW',
                'created-on': 'CREATED ON ',
                'asap': 'ASAP',
                'direct-order': 'Direct Order ',
                'no-content': 'No Content',
                'indirectOrder': 'Indirect Order ',
                'ref-IDS': 'reference IDS',
                'ref-id': 'Reference ID',
                'order-number': 'Order Number',
                'order-ref-id': 'Order Ref ID',
                'createdOn': 'CREATED ON ',
                'pickedUp': 'Picked Up: ',
                'delivered': 'Delivered: ',
                'items': 'items',
                'stops': 'stops',
                'lb': 'lb',
                'lbs': 'lbs',
                'miles': 'miles',
                'returns': 'returns',
                'can-not-edit': 'You can’t edit  the order as it’s already complete!',
                'can-not-cancel': 'You can’t cancel the order!',
                'call-to-action': 'The selected driver is not available. Please modify your driver selection.'
            },
            'status-bar': {
                'completed': 'COMPLETED',
                'exceptions': ' EXCEPTIONS',
                'cancelled': 'CANCELLED',
                'pending-cancellation': 'PENDING CANCELLATION',
                'in-progress': 'IN PROGRESS',
                'awaiting-driver': 'AWAITING DRIVER',
                'declined': 'DECLINED',
                'draft': 'DRAFT',
                'accepted': 'ACCEPTED',
                'released': 'RELEASED'
            },
            'order-event': {
                'order-accepted': 'Order Accepted by Driver',
                'order-released': 'Order Released by Driver',
                'order-changes': 'Order Changes Rejected by Driver',
                'order-creation': 'Order Created',
                'order-cancelled': 'Order Cancelled by {{actor}}',
                'call-to-action': 'Action Required',
                'offer-converted-general': 'Offer Extended to All Drivers',
                'reoffered-by-admin': 'Order Re-offered by TUYA Admin',
                'order-scheduled': 'Scheduled Order Created',
                'scheduled-order-accepted': 'Scheduled Order Accepted by Driver'
            },
            'order-event-role': {
                'shipper': 'Shipper',
                'admin': 'TUYA Admin',
                'driver': 'Driver'
            },
            'stop-event': {
                'stop-arrived': 'Driver Arrived',
                'stop-arrived-cancelled': 'Driver Arrival Cancelled',
                'stop-delivery-completed': 'Order Delivered',
                'stop-pickup-completed': 'Order Picked Up',
                'stop-arrival-attempted': 'Driver Attempted Arrival',
                'stop-item-exception': 'Item Exception'
            },
            'stop-event-status': {
                'completed': 'Completed',
                'new': 'Pending',
                'driver-arrived': 'Arrived',
                'attempted': 'Attempted'
            },
            'stop-event-text': {
                'completed': 'completed',
                'exception': 'exception',
                'via-admin': 'via tuya admin',
                'arrival-pickup': '{{ driver }} arrived for pickup at {{ location }}',
                'arrival-delivery': '{{ driver }} arrived for delivery at {{ location }}',
                'arrival-undo': '{{ driver }} cancelled arrival at {{ location }}',
                'item-exception': '{{ driver }} captured an exception',
                'package': 'item',
                'packages': 'items',
                'arrival-complete-pickup': '{{ num }} {{ units }} picked up at {{ location }}',
                'arrival-complete-delivery': '{{ num }} {{ units }} delivered to {{ location }}',
                'arrival-attempted-pickup': '{{ driver }} attempted pickup at {{ location }}',
                'arrival-attempted-delivery': '{{ driver }} attempted delivery at {{ location }}'
            },
            'stop-event-departure-text': {
                'no-one-here': 'No one is here',
                'cant-access': 'Can\'t access location (locked gate, etc)',
                'other': 'Other',
                'order-cancelled': 'Order was cancelled'
            },
            'stop-completed-arrival-text': {
                'admin-completed-stop': '* Stop completion details updated by TUYA Admin on behalf of the driver.',
                'stop-contact-name-label': 'Stop Contact Name',
                'scheduled-arrival-time-label': 'Scheduled Arrival Time',
                'actual-arrival-time-label': 'Actual Arrival Time*',
                'items-label-delivery': 'Items for Delivery',
                'item-description-label': 'Item Description',
                'items-label-pickup': 'Items for Pickup',
                'total-quantity-label': 'Total Quantity:',
                'total-weight-label': ' Total Weight:',
                'receiver-signature-label': 'Receiver Signature',
                'weight-singular': '1 lb',
                'weight-plural': '{{ num }} lb',
                'total': 'Total',
                'ref-id': 'Ref ID',
                'item-size-copy': {
                    'item-singular': '1 {{ size }} Item',
                    'item-plural': '{{ num }} {{ size }} Items'
                }
            },
            'order-summary-text': {
                'signature-label': 'Contact Signature Required',
                'tracking-link-label': 'Email Tracking Link',
                'contact-info-label': 'Stop Contact Info',
                'contact-name-label': 'Name:',
                'contact-email-label': ' Email:',
                'contact-phone-label': ' Phone:',
            },
            'common-summary-text': {
                'stop-ref-id': 'Stop Ref ID',
                'status': 'Status',
                'admin-complete-btn': 'Complete Stop',
                'admin-undo-complete-btn': 'Undo complete stop',
                'undo-btn-tooltip-assigned': '"Undo Complete Stop" returns the order to the "Assigned" state.',
                'undo-btn-tooltip-inprogress': '"Undo Complete Stop" returns the order to the "In Progress" state.',
                'scheduled-arrival-label': 'Scheduled Arrival',
                'actual-arrival-label': 'Actual Arrival'
            },
            'common-event-text': {
                'driver-comments-label': 'Driver Comments',
                'admin-comments-label': 'Driver Comments*',
                'driver-photos-label': 'Driver Photos',
                'no-content': 'No content submitted',
                'admin-comment': 'TUYA ADMIN COMMENTS'
            },
            'driver-info': {
                'order-history': 'ORDER HISTORY',
                'order-summary': 'ORDER SUMMARY',
                'duplicate': 'DUPLICATE',
                'edit-order': 'EDIT ORDER',
                'order-details': 'ORDER DETAILS',
                'delete': 'DELETE',
                'cancel': 'CANCEL',
                'reoffer': 'RE-OFFER',
                'dispatch': 'DISPATCH',
                'rescue' :'RESCUE',
                'select-reason': 'Select reason',
                'no-driver': 'Awaiting Driver Acceptance',
                'rate-driver': 'Rate Your Driver',
                'driver-satisfaction-score': 'Driver\'s Satisfaction Score',
                'drivers-declined': ' drivers have declined this order.',
                'all-drivers-declined-limited':
                    // tslint:disable-next-line:max-line-length
                    'IMPORTANT: Offer has been declined by all drivers and cannot be completed. Please make another driver selection and opt to extend offer to other qualified drivers.',
                'all-drivers-declined-exclusive1': 'IMPORTANT: Offer has been declined by ',
                'all-drivers-declined-exclusive2':
                    ' and cannot be completed. Please make another driver selection and opt to extend offer to other qualified drivers.',
                'edit-order-link': 'Edit Order',
                'add-driver-placeholder': 'ADD DRIVER TO POOL',
                'added-to-pools': ' added to pools:',
                'feedback-updated': 'Thank you for your feedback!',
                'rate-time-limit': 'Please rate orders within 7 days of their completion.',
                'has-declined-offer' : ' has declined Offer.'
            },
            'driver-rate': {
                'unacceptable': 'Unacceptable',
                'poor': 'Poor',
                'fair': 'Fair, but with room for improvement',
                'good': 'Good, as expected',
                'excellent': 'Excellent, better than expected'
            },
            'modals': {
                'yes': 'Yes',
                'no': 'No',
                'ok': 'Ok',
                'wantCancelOrder': 'Do you want to cancel this Order?',
                'cancelNotOne': 'This order cannot be cancelled because ',
                'cancelNotTwo': ' has already completed the 1st stop at ',
                'adminWantToCancel': 'Do you want to cancel order {{ code }} on behalf of the shipper? All parties will be notified.',
                'comments': 'COMMENTS FOR SHIPPER*',
                'mandatory': 'Type here the reason for the cancellation (mandatory)',
                'dispatch': 'Dispatch Order',
                'dispatch-order': 'You are choosing to dispatch this order on behalf of the shipper. All parties will be notified.',
                'dispatch-please-select': 'Please select the Driver you would like to dispatch this order to:',
                'dispatch-submit': 'dispatch',  
                'reoffer': 'Re-Offer Order',
                'reoffer-order': 'You are choosing to re-offer this order and unassign the current driver on behalf of the shipper. All parties will be notified.',
                'reoffer-please-select': 'Please select the set of Drivers you would like to offer this order to:',
                'reoffer-submit': 're-offer',
                'rescue': 'Rescue Order',
                'rescue-order': 'You are choosing to rescue this order and unassign the current driver on behalf of the shipper. All parties will be notified.',
                'rescue-please-select': 'Please select the Driver you would like to rescue this order:',
                'rescue-submit': 'rescue',
                'cancel': 'Cancel'
            },
            'admin-modal': {
                'title': 'Complete Stop',
                'instructions': `You are completing the stop <strong>{{ location}}</strong>
                     on behalf of the assigned FDP, <strong>{{ driver }}</strong>. This information will be visible to the shipper.`,
                'completion-time': 'Actual Completion Time',
                'comments': 'Stop Comments',
                'confirm': 'Confirm Completion',
                'cancel': 'Cancel',
                'errors': {
                    'time-required': 'Time is required.',
                    'time-pattern': 'Time entered is not valid.',
                    'date-required': 'Date is required.',
                    'date-pattern': 'Date entered is not valid.',
                    'date-range-before-pickup': 'Date entered is before pickup completion.',
                    'date-range-early': 'Date entered is before order creation.',
                    'date-range-late': 'Date entered is in the future.',

                }
            },
            'recurring': {
                'create-title': 'Successful Recurrence Order Setup',
                'update-title': 'Recurring Order Successfully Updated',
                'delete-title': 'Recurring Order Successfully Deleted',
                'create-subtile': 'You’ve successfully created a recurrence order for <b>Order {{ code }}</b>. {{ message }}.',
                'update-subtile': 'You’ve successfully updated your recurring order. This update to the schedule will only affect your recurring orders that have not been offered.',
                'delete-subtile': 'You’ve successfully deleted your recurring order.This update to the schedule will only affect your recurring orders that have not been offered.'
            },
            'errors': {
                'error1': 'Sorry, we\'re having trouble retrieving history events for this shipment.'
            },
        }
    }
};
