import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  QueryList,
  AfterViewInit
} from '@angular/core';
import { Observable } from 'rxjs';
import { ShipmentSelectors } from 'app/shipment/selectors/shipment.selectors';
import { select } from '@angular-redux-ivy/store';
import { Marker, LatLng } from 'app/core/models/map.model';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';

declare const google: any;

export type Mode = 'fullscreen' | 'container-size' | 'fixed-size';

export enum Reposition {
  none = 0,
  static = 1,
  fixed = 2
}

export const Modes = {
  fullscreen: <Mode>'fullscreen',
  containerSize: <Mode>'container-size',
  fixedSize: <Mode>'fixed-size'
};

@Component({
  selector: 'tuya-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapComponent implements OnChanges, AfterViewInit {
  @ViewChild(GoogleMap, { static: false }) public map: GoogleMap;
  @ViewChildren(MapInfoWindow) infoWindows: QueryList<MapInfoWindow>;
  @ViewChildren(MapMarker) markerElements: QueryList<MapMarker>;

  @Input() markers: Marker[] = [];
  @Input() mapCenter: LatLng = { lat: 31.9686, lng: -99.9018 }; // Center of Texas
  @Input() activeMarkerId: string = '';
  @Input() autoBoundary = false;
  @Input() clickableIcons = true;
  @Input() minZoom = 3;
  @Input() screenChangeMode = false;

  public zoom = 10;
  public _mapIsReady = false;
  private _allowCenterChange = true;
  private prevMode: Mode;
  private markerInstances: google.maps.Marker[] = [];  // Define markerInstances as an array of google.maps.Marker objects

  @Output() mapClicked = new EventEmitter();
  @Output() markerClicked = new EventEmitter();
  @Output() markerDragged = new EventEmitter();

  @select(ShipmentSelectors.mapCenterDefault) mapDefaultCenter$: Observable<LatLng>;

  constructor(private elementRef: ElementRef, @Attribute('mode') public mode: Mode = Modes.containerSize) {
    this.mapDefaultCenter$.subscribe(res => {
      this.mapCenter = res;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this._isAdjustMapProcess(changes) && this._mapIsReady) {
      console.log("Markers changed, updating bounds.");
      this.createMarkers(); // Call createMarkers when markers input changes
      this.updateBounds();
    }
  }

  ngAfterViewInit() {
    // Wait for the view to initialize before creating markers
    if (this.map) {
      this.mapReady(); // Ensure mapReady is called if map is already initialized
    }
  }

  mapReady() {
    this._mapIsReady = true;
    console.log("Map is ready, creating markers.");
    this.createMarkers(); // Call createMarkers when map is ready
    this.updateBounds(); // Update bounds once markers are created
  }

  centerChange(event: google.maps.LatLngLiteral) {
    if (this._allowCenterChange) {
      this.mapCenter = event;
    }
    this._allowCenterChange = false;
  }

  zoomChange(event: number) {
    this.zoom = event;
  }

  changeModeClickHandler(mode: Mode) {
    let delta;
    if (mode !== Modes.fullscreen) {
      this.prevMode = mode;
      this.mode = Modes.fullscreen;
      delta = Reposition.none;
    } else {
      this.mode = this.prevMode || Modes.containerSize;
      delta = Reposition.static;
    }
    if (this.map && this.map.googleMap) {
      this.map.googleMap.fitBounds(this.map.getBounds());
      this.updateBounds();
    }
  }

  onDragEnd(event: google.maps.MapMouseEvent, marker: Marker) {
    this.markerDragged.emit({ coords: event.latLng.toJSON(), marker });
    if (this._mapIsReady) {
      this.updateBounds();
    }
  }

  openInfoWindow(infoWindow: MapInfoWindow, marker: MapMarker) {
    this.infoWindows.forEach(window => window.close());
    infoWindow.open(marker);
  }

  closeInfoWindow(infoWindow: MapInfoWindow) {
    infoWindow.close();
  }

  trackByFn(index, item) {
    return item.stopId; // Use stopId as a unique identifier
  }

  private _isAdjustMapProcess(changes: SimpleChanges) {
    return this._isAdjustMarkerProcess(changes.markers) || this._isAdjustActiveMarkerProcess(changes.activeMarkerId);
  }

  private _isAdjustMarkerProcess(markers) {
    if (!markers || !markers.currentValue.length) {
      return false;
    }
    return JSON.stringify(markers.currentValue) !== JSON.stringify(markers.previousValue);
  }

  private _isAdjustActiveMarkerProcess(activeMarkerId) {
    if (!activeMarkerId || !activeMarkerId.currentValue || !activeMarkerId.currentValue.length) {
      return false;
    }
    return activeMarkerId.currentValue !== activeMarkerId.previousValue;
  }

  /*private updateBounds() {
    if (this.map && this.map.googleMap && this.markers.length > 1) {
      const bounds = new google.maps.LatLngBounds();
      this.markers.forEach(marker => {
        const position = new google.maps.LatLng(marker.latLng.lat, marker.latLng.lng);
        bounds.extend(position);
      });

      // Create a buffer around the bounds
      const bufferDistance = 0.01; // Adjust this value for desired buffer distance
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();

      // Extend the bounds by the buffer distance
      const extendedBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(sw.lat() - bufferDistance, sw.lng() - bufferDistance),
        new google.maps.LatLng(ne.lat() + bufferDistance, ne.lng() + bufferDistance)
      );

      this.map.googleMap.fitBounds(extendedBounds);
      return;
    }

    this.centerMapOnLocation();
  }*/

  private updateBounds() {
    if (this.map && this.map.googleMap && this.markers.length > 1) {
      const bounds = new google.maps.LatLngBounds();
      this.markers.forEach(marker => {
        const position = new google.maps.LatLng(marker.latLng.lat, marker.latLng.lng);
        bounds.extend(position);
        //console.log(`Extending bounds with position: ${position.toString()}`);
      });
      //console.log(`Fitting bounds: ${bounds.toString()}`);
      this.map.googleMap.fitBounds(bounds);
    } else {
      //console.log("Setting user location as map center.");
      this.centerMapOnLocation();
    }
  }

  /*private setUserLocationAsMapCenter() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.mapCenter = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          if (!this.markers.length) this.centerMapOnLocation();
        },
        (error) => {
          this.centerMapOnLocation();
        }
      );
    } else {
      this.centerMapOnLocation();
    }
  }*/

  private centerMapOnLocation() {
    if (this.map && this.map.googleMap) {
      this.map.googleMap.setCenter(new google.maps.LatLng(this.mapCenter.lat, this.mapCenter.lng));
      this.map.googleMap.setZoom(this.zoom);
    }
  }

  createMarkers() {
    if (!this.map) return;

    // Clear existing markers
    this.markerInstances.forEach(marker => marker.setMap(null));
    this.markerInstances = [];

    // Create new markers and info windows
    this.markers.forEach((m, i) => {
      const marker = new google.maps.Marker({
        position: m.latLng,
        icon: m.stopId === this.activeMarkerId ? m.activeIconUrl : m.iconUrl,
        draggable: m.draggable,
        map: this.map.googleMap
      });

      const infoWindow = new google.maps.InfoWindow({
        content: `
              <div class="map-active-tooltip">
                <div class="info-window-header">
                  ${m.infoWindow.infoWindowHeader}
                </div>
                <div class="info-window-body">
                  ${m.infoWindow.infoWindowBody}
                </div>
              </div>
            `
      });

      marker.addListener('dragend', (event: google.maps.MapMouseEvent) => {
        this.onDragEnd(event, m);
      });

      // Attach click event (uses the onClick callback if defined)
      marker.addListener('click', () => {
        if (m.onClick) {
            m.onClick(m.stopId); // Invoke the custom onClick handler
        } else {
            console.warn(`No onClick handler defined for marker with stopId: ${m.stopId}`);
        }
    });

      marker.addListener('mouseover', () => {
        this.closeAllInfoWindows(); // Close all other info windows
        if (marker.icon.includes('empty')) return; // do not show blank info windows
        infoWindow.open(this.map.googleMap, marker);
      });

      marker.addListener('mouseout', () => {
        infoWindow.close();
      });

      this.markerInstances.push(marker);
    });
  }

  closeAllInfoWindows() {
    this.markerInstances.forEach(marker => {
      const infoWindow = marker.get('infoWindow');
      if (infoWindow) {
        infoWindow.close();
      }
    });
  }
}