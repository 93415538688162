import {
    Component,
    OnInit,
    ElementRef,
    ViewChild,
    OnDestroy,
} from '@angular/core';
import { select } from '@angular-redux-ivy/store';
import { Observable, takeUntil, interval, tap, startWith } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { PoolsActions } from '../../../core/actions/pools.actions';
import { ShipperResult } from '../../models/admin.model';
import { ShipperAdminActions } from '../../actions/shipper.admin.actions';
import { Subject } from 'rxjs';
import { LatLng, Marker } from 'app/core/models/map.model';
import { MarkerUrls } from 'app/core/constants/general.constants';
import { IAppState } from 'app/store/model';
import { DriversLocationsDto, StopTypeId } from 'app/core/models/dto';

export class AdminSelectors {
    public static getDriversLocations = (
        state: IAppState
    ): DriversLocationsDto[] => state.admin.shipperAdmin.drivers;
    public static mapCenterSelector = (state: IAppState): LatLng =>
        state.core.map.mapCenter;
}

@Component({
    selector: 'tuya-admin-home',
    templateUrl: './admin-home.component.html',
    styleUrls: ['./admin-home.component.scss'],
})
export class AdminHomeComponent implements OnInit, OnDestroy {
    @select(['admin', 'shipperAdmin', 'shipperResults'])
    readonly shipperResults$: Observable<ShipperResult[]>;
    @select(['admin', 'shipperAdmin', 'shipperResultsCount'])
    readonly shipperResultsCount$: Observable<number>;
    @select(['admin', 'shipperAdmin', 'isLoading'])
    readonly isLoading$: Observable<boolean>;
    @select(['admin', 'shipperAdmin', 'shipperSearchTerm'])
    readonly shipperSearchTerm$: Observable<string>;
    @select(['admin', 'shipperAdmin', 'shipperSearchCurrentPage'])
    readonly shipperSearchCurrentPage$: Observable<number>;
    @select(['admin', 'shipperAdmin', 'shipperSearchResultsPerPage'])
    readonly shipperSearchResultsPerPage$: Observable<number>;

    @select(AdminSelectors.mapCenterSelector)
    mapCenter$: Observable<LatLng>;

    @select(AdminSelectors.getDriversLocations)
    drivers$: Observable<DriversLocationsDto[]>;

    shipperSearchResultsPerPage = new BehaviorSubject<number>(0);
    searchTerm: string;
    shipperSearchTerm;
    shipperResults;
    templateMath: any;
    shipperSearchCurrentPage = null;
    markers: Marker[] = [];
    drivers: DriversLocationsDto[] = [];
    unsubscriber = new Subject();

    @ViewChild('searchBox', { static: true }) inputEl: ElementRef;

    constructor(
        private actions: ShipperAdminActions,
        private poolActions: PoolsActions
    ) {
        this.shipperSearchResultsPerPage$.subscribe(
            this.shipperSearchResultsPerPage
        );
        this.templateMath = Math;
        this.shipperSearchTerm$.subscribe(
            (res) => (this.shipperSearchTerm = res)
        );
        this.shipperResults$.subscribe((res) => (this.shipperResults = res));
        this.drivers$.subscribe((result) => (this.drivers = result));
    }

    ngOnInit() {
        setTimeout(() => this.inputEl.nativeElement.focus());

        this.shipperSearchCurrentPage$
            .pipe(takeUntil(this.unsubscriber))
            .subscribe((res) => {
                this.shipperSearchCurrentPage = res;
            });

        // Initial fetch and periodic refresh every 10 minutes (600000 milliseconds)
        interval(600000)
            .pipe(
                startWith(0),
                takeUntil(this.unsubscriber),
                tap(() => {
                    this.clearMarkers();
                    this.actions.loadDriverLocations();
                })
            )
            .subscribe();

        // Subscribe to drivers$ and update markers
        this.drivers$
            .pipe(takeUntil(this.unsubscriber))
            .subscribe((drivers) => {
                this.markers = this.createMarkers(drivers); // Create new markers based on the updated data
            });
    }

    ngOnDestroy() {
        this.unsubscriber.next(true);
        this.unsubscriber.unsubscribe();
    }

    onSubmit() {
        if (this.searchTerm !== undefined && this.searchTerm.trim() !== '') {
            this.actions.shipperSearch({
                value: this.searchTerm.trim(),
                pageSize: this.shipperSearchResultsPerPage.value,
                currentPageNumber: 1,
                newSearch: true,
            });
        }
    }

    nextPage() {
        this.actions.shipperTablePaginate(this.shipperSearchCurrentPage + 1);
    }

    previousPage() {
        this.actions.shipperTablePaginate(this.shipperSearchCurrentPage - 1);
    }

    selectShipper(userAccountId: number) {
        this.actions.setIsLoadingTrue();
        this.actions.loadShipperAndCompanyProfilesAsAdmin(userAccountId);
    }

    boldSearchTerm(text) {
        // this uses the <strong> HTML element rather than a style.
        // to add a style, we'd need to incorporate DOMSanitizer.bypassSecurityTrustStyle
        // phone number has problems if spanning over a '.'
        const re = new RegExp(this.shipperSearchTerm, 'gi'); // g = global. i = case insensitive
        return text.replace(re, (str) => {
            return `<strong>${str}</strong>`; // return the original string, not the searched string
        });
    }

    /**
     * Filter out shippers that have null values in their data and check if there are any remaining
     * in the array.
     */
    shippersHaveValidData() {
        return Boolean(
            this.shipperResults.filter((shipper) => {
                for (const prop in shipper) {
                    if (shipper.hasOwnProperty(prop) && shipper[prop] === null)
                        return false;
                }
                return true;
            }).length
        );
    }

    // clear markers (map pins)
    private clearMarkers(): void {
        this.markers = []; // Reset the markers array
    }

    // Driver Locations (Real-time Driver Tracking)
    public createMarkers(drivers: DriversLocationsDto[]): Marker[] {
        const markers: Marker[] = [];

        for (const driver of drivers) {
            if (!driver || !driver.name) continue;

            const latitude =
                driver.latitude !== undefined ? Number(driver.latitude) : NaN;
            const longitude =
                driver.longitude !== undefined ? Number(driver.longitude) : NaN;

            if (isNaN(latitude) || isNaN(longitude)) continue;
            if (latitude === 0 || longitude === 0) continue;
            if (driver.marker < 0 || driver.marker > StopTypeId.DriverNotActive)
                continue;

            const latLng: LatLng = {
                lat: latitude,
                lng: longitude,
            };

            const marker: Marker = {
                latLng: latLng,
                stopId: String(driver.id),
                infoWindow: {
                    infoWindowHeader: driver.name,
                    infoWindowBody: this.formatDate(driver.timestamp),
                },
                activeIconUrl: MarkerUrls[driver.marker]?.activeIconUrl,
                iconUrl: MarkerUrls[driver.marker]?.iconUrl,
                ...(driver.marker === StopTypeId.PickedUp && {
                    onClick: (stopId: any) => this.handleShipmentMarkerClick(stopId),
                })
            };

            markers.push(marker);
        }

        if (markers.length === 0) return this.createDefaultMarker();
        return markers;
    }

    private handleShipmentMarkerClick(userAccountId: string): void {
        this.actions.setIsLoadingTrue();
        this.actions.loadShipperAndCompanyProfilesAsAdmin(userAccountId);
    }

    /*
                activeIconUrl: this.isOlderThan10Min(driver.timestamp)
                    ? MarkerUrls[6]?.activeIconUrl
                    : MarkerUrls[5]?.activeIconUrl,
                iconUrl: this.isOlderThan10Min(driver.timestamp)
                    ? MarkerUrls[6]?.iconUrl
                    : MarkerUrls[5]?.iconUrl,

      isOlderThan10Min(timestamp: string | number | Date): boolean {
        const currentTime = new Date().getTime();
        const driverTimestamp = new Date(timestamp).getTime();
        return currentTime - driverTimestamp > 10 * 60 * 1000; // 10 minutes in milliseconds
    }*/

    private formatDate(dateString) {
        const date = new Date(dateString);

        const month = date.getMonth() + 1; // 0 based Month in JavaScript
        const day = date.getDate(); // use getDate() instead of getDay()
        const year = date.getFullYear();

        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'

        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${formattedMonth}/${formattedDay}/${year}, ${hours}:${formattedMinutes} ${ampm}`;
    }

    private createDefaultMarker(): Marker[] {
        const defaultLatLng: LatLng = {
            lat: 29.7604, // Latitude for Houston
            lng: -95.3698, // Longitude for Houston
        };

        const defaultMarker: Marker = {
            latLng: defaultLatLng,
            stopId: 'default',
            infoWindow: {
                infoWindowHeader: '',
                infoWindowBody: '',
            },
            activeIconUrl: MarkerUrls[0]?.activeIconUrl,
            iconUrl: MarkerUrls[0]?.iconUrl,
        };

        return [defaultMarker];
    }
}
