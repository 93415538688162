import { Injectable } from '@angular/core';
import { dispatch } from '@angular-redux-ivy/store';
import { OrderDto } from '../../builder/models/order.model';
import { OfferType } from '../../builder/models/order.model';
import { Action } from '../../core/models/redux.action';
import { SelectedCandidatesDto } from '../../core/models/order.dto';
import { ShipperProfileDto, BusinessEntityDto, DriversLocationsDto } from '../../core/models/dto';
import { ProfileToEditVO } from '../../register/model/model';

export type ShipperAdminAction = Action<any, any>;

export const SHIPPER_SEARCH = '[Admin] Search for Shipper';
export const SHIPPER_SEARCH_SUCCEEDED = '[Admin] Search for Shipper Success';
export const SHIPPER_SEARCH_FAILED = '[Admin] Search for Shipper Fail';
export const SHIPPER_TABLE_PAGINATE = '[Admin] Paginate Shipper Search Table';
export const SELECT_SHIPPER = '[Admin] Select Shipper';
export const LOAD_SHIPPER_AND_COMPANY_PROFILES_AS_ADMIN = '[Admin] Load Shipper and Company Profiles as Admin';
export const LOAD_SHIPPER_AND_COMPANY_PROFILES_AS_ADMIN_SUCCESS = '[Admin] Load Shipper and Company Profiles as Admin Success';
export const LOAD_SHIPPER_AND_COMPANY_PROFILES_AS_ADMIN_FAIL = '[Admin] Load Shipper and Company Profiles as Admin Fail';
export const SAVE_SHIPPER_PROFILE_AS_ADMIN_SUCCESS = '[Admin] Save Shipper Profile as Admin Success';
export const SAVE_COMPANY_PROFILE_AS_ADMIN_SUCCESS = '[Admin] Save Company Profile as Admin Success';
export const COMPLETE_STOP = '[Admin] Complete Stop';
export const COMPLETE_STOP_SUCCESS = '[Admin] Complete Stop Success';
export const COMPLETE_STOP_FAILURE = '[Admin] Complete Stop Failure';
export const ADMIN_DISPATCH_EDIT_ACTION = '[Admin] Dispatch Edit Call';
export const ADMIN_DISPATCH_EDIT_ACTION_SUCCEEDED = '[Admin] Dispatch Edit Call Succeeded';
export const ADMIN_DISPATCH_EDIT_ACTION_FAILED = '[Admin] Dispatch Edit Call Failed';
export const ADMIN_REOFFER_EDIT_ACTION = '[Admin] Reoffer Edit Call';
export const ADMIN_REOFFER_EDIT_ACTION_SUCCEEDED = '[Admin] Reoffer Edit Call Succeeded';
export const ADMIN_REOFFER_EDIT_ACTION_FAILED = '[Admin] Reoffer Edit Call Failed';
export const ADMIN_RESCUE_EDIT_ACTION = '[Admin] Rescue Edit Call';
export const ADMIN_RESCUE_EDIT_ACTION_SUCCEEDED = '[Admin] Rescue Edit Call Succeeded';
export const ADMIN_RESCUE_EDIT_ACTION_FAILED = '[Admin] Rescue Edit Call Failed';
export const ADMIN_DISPATCH_ORDER = '[Admin] Dispatch Order';
export const ADMIN_DISPATCH_ORDER_SUCCESS = '[Admin] Dispatch Order Success';
export const ADMIN_DISPATCH_ORDER_FAILURE = '[Admin] Dispatch Order Failure';
export const ADMIN_REOFFER_ORDER = '[Admin] Reoffer Order';
export const ADMIN_REOFFER_ORDER_SUCCESS = '[Admin] Reoffer Order Success';
export const ADMIN_REOFFER_ORDER_FAILURE = '[Admin] Reoffer Order Failure';
export const ADMIN_RESCUE_ORDER = '[Admin] Rescue Order';
export const ADMIN_RESCUE_ORDER_SUCCESS = '[Admin] Rescue Order Success';
export const ADMIN_RESCUE_ORDER_FAILURE = '[Admin] Rescue Order Failure';
export const ADMIN_SET_REOFFER_EXTENDABLE = '[Admin] Set Reoffer Extendable';
export const ADMIN_SET_SELECTED_CANDIDATES = '[Admin] Update Selected Candidates';
export const ADMIN_SET_OFFER_TYPE = '[Admin] Update Offer Type';
export const ADMIN_CLEAR_DISPATCH = '[Admin] Clear Current Dispatch';
export const ADMIN_CLEAR_REOFFER = '[Admin] Clear Current Reoffer';
export const ADMIN_CLEAR_RESCUE = '[Admin] Clear Current Dispatch';
export const SET_IS_LOADING_TRUE = '[Admin] Set isLoading to true';
export const SET_IS_LOADING_FALSE = '[Admin] Set isLoading to false';
export const ADMIN_RESET_STORE_VALUES = '[Admin] Reset state values';
export const UNDO_STOP_COMPLETE = '[Admin] Undo Complete';
export const UNDO_STOP_COMPLETE_SUCCESS = '[Admin] Undo complete Stop Success';
export const UNDO_STOP_COMPLETE_FAILURE = '[Admin] Undo complete Stop Failure';
export const LOAD_DRIVER_LOCATIONS = '[Admin] Load Driver Locations';
export const LOAD_DRIVER_LOCATIONS_SUCCESS = '[Admin] Load Driver Locations Success';
export const LOAD_DRIVER_LOCATIONS_FAILURE = '[Admin] Load Driver Locations Failure';

@Injectable()
export class ShipperAdminActions { 
  @dispatch()
  shipperSearch = (data: any): ShipperAdminAction => ({
      type: SHIPPER_SEARCH,
      payload: data
  })

  shipperSearchSuccess = (data: any): ShipperAdminAction => ({
    type: SHIPPER_SEARCH_SUCCEEDED,
    payload: data
  })

  shipperSearchFail = (error: any): ShipperAdminAction => ({
    type: SHIPPER_SEARCH_FAILED,
    payload: null,
    error
  })

  @dispatch()
  shipperTablePaginate = (page: number): ShipperAdminAction => ({
    type: SHIPPER_TABLE_PAGINATE,
    payload: page
  })

  @dispatch()
  selectShipper = (data: any): ShipperAdminAction => ({
    type: SELECT_SHIPPER,
    payload: data
  })

  @dispatch()

  loadShipperAndCompanyProfilesAsAdmin = (userAccountId: any): ShipperAdminAction => ({
    type: LOAD_SHIPPER_AND_COMPANY_PROFILES_AS_ADMIN,
    payload: userAccountId
  })

  loadShipperAndCompanyProfilesAsAdminSuccess = (shipperProfile: ShipperProfileDto): ShipperAdminAction => ({
    type: LOAD_SHIPPER_AND_COMPANY_PROFILES_AS_ADMIN_SUCCESS,
    payload: shipperProfile
  })

  loadShipperAndCompanyProfilesAsAdminFail = (error: any): ShipperAdminAction => ({
    type: LOAD_SHIPPER_AND_COMPANY_PROFILES_AS_ADMIN_FAIL,
    payload: error
  })

  saveShipperProfileAsAdminSuccess = (profileToEdit: ProfileToEditVO): ShipperAdminAction => ({
    type: SAVE_SHIPPER_PROFILE_AS_ADMIN_SUCCESS,
    payload: profileToEdit
  })

  saveCompanyProfileAsAdminSuccess = (profile: BusinessEntityDto): ShipperAdminAction => ({
    type: SAVE_COMPANY_PROFILE_AS_ADMIN_SUCCESS,
    payload: profile
  })

  @dispatch()
  completeStop = (data: any): ShipperAdminAction => ({
    type: COMPLETE_STOP,
    payload: data
  })

  completeStopSuccess = (): ShipperAdminAction => ({
    type: COMPLETE_STOP_SUCCESS,
    payload: null
  })

  completeStopFailure = (): ShipperAdminAction => ({
    type: COMPLETE_STOP_FAILURE,
    payload: null
  })

  @dispatch()
  dispatchEditOrder = (orderId: any): ShipperAdminAction => ({
      type: ADMIN_DISPATCH_EDIT_ACTION,
      payload: orderId
  })

  dispatchEditOrderSucceeded = (orderVo: OrderDto): ShipperAdminAction => ({
    type: ADMIN_DISPATCH_EDIT_ACTION_SUCCEEDED,
    payload: orderVo
  })

  dispatchEditOrderFailed = (error: any): ShipperAdminAction => ({
      type: ADMIN_DISPATCH_EDIT_ACTION_FAILED,
      payload: null,
      error
  })

  @dispatch()
  reofferEditOrder = (orderId: any): ShipperAdminAction => ({
      type: ADMIN_REOFFER_EDIT_ACTION,
      payload: orderId
  })

  reofferEditOrderSucceeded = (orderVo: OrderDto): ShipperAdminAction => ({
    type: ADMIN_REOFFER_EDIT_ACTION_SUCCEEDED,
    payload: orderVo
  })

  reofferEditOrderFailed = (error: any): ShipperAdminAction => ({
      type: ADMIN_REOFFER_EDIT_ACTION_FAILED,
      payload: null,
      error
  })

  @dispatch()
  rescueEditOrder = (orderId: any): ShipperAdminAction => ({
      type: ADMIN_RESCUE_EDIT_ACTION,
      payload: orderId
  })

  rescueEditOrderSucceeded = (orderVo: OrderDto): ShipperAdminAction => ({
    type: ADMIN_RESCUE_EDIT_ACTION_SUCCEEDED,
    payload: orderVo
  })

  rescueEditOrderFailed = (error: any): ShipperAdminAction => ({
      type: ADMIN_RESCUE_EDIT_ACTION_FAILED,
      payload: null,
      error
  })

  @dispatch()
  dispatchOrder = (order: any): ShipperAdminAction => ({
    type: ADMIN_DISPATCH_ORDER,
    payload: order
  })

  dispatchOrderSuccess = (order: any): ShipperAdminAction => ({
    type: ADMIN_DISPATCH_ORDER_SUCCESS,
    payload: order
  })

  dispatchOrderFailure = (error: any): ShipperAdminAction => ({
    type: ADMIN_DISPATCH_ORDER_FAILURE,
    payload: null,
    error
  })

  @dispatch()
  reofferOrder = (order: any): ShipperAdminAction => ({
    type: ADMIN_REOFFER_ORDER,
    payload: order
  })

  reofferOrderSuccess = (order: any): ShipperAdminAction => ({
    type: ADMIN_REOFFER_ORDER_SUCCESS,
    payload: order
  })

  reofferOrderFailure = (error: any): ShipperAdminAction => ({
    type: ADMIN_REOFFER_ORDER_FAILURE,
    payload: null,
    error
  })

  @dispatch()
  rescueOrder = (order: any): ShipperAdminAction => ({
    type: ADMIN_RESCUE_ORDER,
    payload: order
  })

  rescueOrderSuccess = (order: any): ShipperAdminAction => ({
    type: ADMIN_RESCUE_ORDER_SUCCESS,
    payload: order
  })

  rescueOrderFailure = (error: any): ShipperAdminAction => ({
    type: ADMIN_RESCUE_ORDER_FAILURE,
    payload: null,
    error
  })

  @dispatch()
  setReofferExtendable = (isExtendable: boolean): ShipperAdminAction => ({
    type: ADMIN_SET_REOFFER_EXTENDABLE,
    payload: isExtendable
  })

  @dispatch()
  updateSelectedCandidates = (selectedCandidates: SelectedCandidatesDto): ShipperAdminAction => ({
    type: ADMIN_SET_SELECTED_CANDIDATES,
    payload: selectedCandidates
  })

  @dispatch()
  updateOfferType = (offerType: OfferType): ShipperAdminAction => ({
      type: ADMIN_SET_OFFER_TYPE,
      payload: offerType
  })

  @dispatch()
  clearDispatch = (): ShipperAdminAction => ({
    type: ADMIN_CLEAR_DISPATCH,
    payload: null
  })

  @dispatch()
  clearReoffer = (): ShipperAdminAction => ({
    type: ADMIN_CLEAR_REOFFER,
    payload: null
  })

  @dispatch()
  clearRescue = (): ShipperAdminAction => ({
    type: ADMIN_CLEAR_RESCUE,
    payload: null
  })

  @dispatch()
  setIsLoadingTrue = (): ShipperAdminAction => ({
    type: SET_IS_LOADING_TRUE,
    payload: null
  })

  @dispatch()
  setIsLoadingFalse = (): ShipperAdminAction => ({
    type: SET_IS_LOADING_FALSE,
    payload: null
  })

  @dispatch()
  resetAdminStoreValues = (): ShipperAdminAction => ({
    type: ADMIN_RESET_STORE_VALUES,
    payload: null
  })

  @dispatch()
  undoStopComlete = (payload: any): ShipperAdminAction => ({
    type: UNDO_STOP_COMPLETE,
    payload: payload
  })
  undoStopComleteSuccess = (): ShipperAdminAction => ({
    type: UNDO_STOP_COMPLETE_SUCCESS,
    payload: null
  })
  undoStopComleteFailure = (): ShipperAdminAction => ({
    type: UNDO_STOP_COMPLETE_FAILURE,
    payload: null
  })

  @dispatch()
  loadDriverLocations = (): ShipperAdminAction => ({
      type: LOAD_DRIVER_LOCATIONS,
      payload: null
  })
  loadDriverLocationsSucceeded = (drivers: DriversLocationsDto[]): ShipperAdminAction => ({
      type: LOAD_DRIVER_LOCATIONS_SUCCESS,
      payload: drivers
  })
  loadDriverLocationsFailed = (error: any): ShipperAdminAction => ({
      type: LOAD_DRIVER_LOCATIONS_FAILURE,
      payload: null,
      error: error
  })
}