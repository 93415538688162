<div class="container scrollable">
    <div class="content">
        <h1 [translate]="'tuya-shipment.general-offer.who-deliver'"></h1>
        <p>
            <span [translate]="'tuya-shipment.general-offer.order-require-driver'"></span>
            <span class="vehicle-name">{{vehicleName}}.</span>
        </p>
    </div>
    <div class="form-group">
        <div class="radio-button">
            <div class="general-offer-row">
                <label class="custom-radio-container any">
                    <input
                        name="anyDriver"
                        type="radio"
                        (ngModelChange)="driverTypeClick($event)"
                        [value]="DriverAssignmentTypeId.Any"
                        [disabled]="isDisaledDriverSection()"
                        [(ngModel)]="driverAssignmentType">
                    <span [translate]="'tuya-shipment.general-offer.radio.any-driver'"></span>
                    <span class="checkmark"
                          tooltip="{{'tuya-shipment.unable-change' | translate}}"
                          placement="top"
                          container="body"
                          containerClass="tuya-tooltip"
                          [isDisabled]="getDisabledAnyStatement()"></span>
                </label>
            </div>
            
            <div class="general-offer-row">
                <label class="custom-radio-container exclusive">
                    <input
                        name="specificDriver"
                        type="radio"
                        (ngModelChange)="driverTypeClick($event)"
                        [value]="DriverAssignmentTypeId.Exclusive"
                        [disabled]="isDisaledDriverSection()"
                        [(ngModel)]="driverAssignmentType">
                    <span [translate]="'tuya-shipment.general-offer.radio.specific-driver'"></span>
                    <span class="checkmark"
                          tooltip="{{'tuya-shipment.unable-change' | translate}}"
                          placement="top"
                          container="body"
                          [isDisabled]="getDisabledExclusiveStatement()"
                          containerClass="tuya-tooltip" ></span>
                </label>

                <div class="general-offer-select-container">
                    <tuya-ng-select #driverPoolsSelect [multiple]="true"
                            [items]="pools"
                            [initialField]="'drivers'"
                            [disabled]="disabledLimited || isDisaledDriverSection()"
                            [hasAddButton]="false"
                            [hasSubmitButton]="false"
                            (submitSelected)="submitSelectedPools($event)"
                            (data)="submitSelectedPools($event)"
                            [staticItem]="'true'"
                            staticItemLabel="{{'tuya-shipment.general-offer.no-matches' | translate}}"
                            placeholder="{{'tuya-shipment.general-offer.select-driver' | translate}}">
                    </tuya-ng-select>
                </div>

                <div class="general-offer-select-container">
                    <tuya-ng-select #exclusiveDriversSelect [multiple]="true"
                            [textField]="'fullName'"
                            [idField]="'userAccountId'"
                            [hasAddButton]="false"
                            [hasSubmitButton]="false"
                            [disabled]="disabledExclusive || isDisaledDriverSection()"
                            [pending]="isDriversLoading"
                            (submitSelected)="submitSelectedDrivers($event)"
                            (data)="submitSelectedDrivers($event)"
                            (typed)="onTypedEventHandler($event)"
                            [staticItem]="'true'"
                            staticItemLabel="{{'tuya-shipment.general-offer.no-matches' | translate}}"
                            placeholder="{{'tuya-shipment.general-offer.enter-driver' | translate}}">
                    </tuya-ng-select>
                </div>
                <div class="re-offer-container">
                    <div class="form-checkbox">
                        <label for="re-offer-order" class="control control-checkbox">
                            <span [translate]="'tuya-shipment.general-offer.re-offer'"></span>
                            <input type="checkbox" [(ngModel)]="isExtendable" id="re-offer-order" [disabled]="disabledIsExtendable" (change)="changeExtendable()" />
                            <div class="control_indicator"></div>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <tuya-price-optimization-modal #priceOptimizationModal
                                       *ngIf="priceOptimizationData.startOptimization"
                                       [price]="delta"
                                       [oldPrice]="oldPrice"
                                       [currentPrice]="currentPrice"
                                       [alreadyOptimizedPrice]="alreadyOptimizedPrice"
                                       [priceOptProccesingCompleted]="priceOptimizationData.finishOptimization"
                                       [priceOptimized]="priceOptimizationData.isOptimized"
                                       (hideModal)="onHidePriceOptimizationModal()"
        >
        </tuya-price-optimization-modal>
        <tuya-price-optimization-error-modal *ngIf="priceOptimizationData.hasErrors"
                                             [unservedStopNames]="priceOptimizationData.unservedStopNames"
                                             (hideModal)="editOrder.emit()"
        >

        </tuya-price-optimization-error-modal>
    </div>
</div>


